import React from "react";

import { Detector } from "react-detect-offline";
import AllRoutes from "./routes/Routes";

// import { configureFakeBackend } from "./helpers";

// For Default import Default.scss
import "./assets/scss/Default.scss";

// For Saas import Saas.scss
// import './assets/scss/Saas.scss';

// For Modern demo import Modern.scss
// import './assets/scss/Modern.scss';

// For Creative demo import Creative.scss
// import "./assets/scss/Creative.scss";

// For Purple demo import Purple.scss
// import './assets/scss/Purple.scss';

// For Material demo import Material.scss
// import './assets/scss/Material.scss';

// Other
import "./assets/scss/Landing.scss";
import "./assets/scss/Icons.scss";
import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";

// configure fake backend
// configureFakeBackend();

const App = () => {
  const configPolling = {
    enabled: true,
    url: 'google.com',
    interval: 60000,
    timeout: 60000,
  };

  return (
    <>
      <React.Fragment>
        <AllRoutes />
      </React.Fragment>
      <Detector
        polling={configPolling}
        render={({ online }) => {
          if(online){
            document?.getElementById('offline')?.setAttribute("style","display:none")
            document?.getElementById('wrapper')?.classList?.remove('offline-container');
          } else {
            document?.getElementById('offline')?.removeAttribute("style")
            document?.getElementById('wrapper')?.classList?.add('offline-container');
          }
          // online
          //   ? () => document?.getElementById('wrapper')?.classList?.add('offline-container')
          //   : document?.getElementById('wrapper')?.classList?.add('offline-container');
          return <></>;
        }}
      />
    </>
  );
};

export default App;
